import { graphql } from "gatsby";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import React from "react";
import Layout from "../components/layout";
import { FaDownload } from "react-icons/fa";
import Seo from "../components/SEO";
import { RichText } from "prismic-reactjs";
import { css } from "@emotion/react";
import CTA from "../components/CTA";
import OnlineShop from "../components/OnlineShop";
import FaQ from "../components/FaQ";
import Kontakt from "../components/Kontakt";
export default function Page({ data }) {
  return (
    <Layout>
      <Seo data={data?.prismicPage?.seo} />
      {data?.prismicPage?.data?.body?.map((slice, index) => {
        switch (slice.slice_type) {
          case "text":
            return (
              <Text
                key={index}
                html={slice?.primary?.text?.html}
                raw={slice?.primary?.text?.raw}
              />
            );
          case "call_to_action":
            return <CTA data={slice} key={index} />;
          case "online_shop":
            return <OnlineShop data={slice} key={index} />;
          case "contact_form":
            return <Kontakt key={index} title={slice?.primary?.title?.text} />;
          case "media":
            return <MediaObject key={index} data={slice} />;
          case "faq_section":
            return <FaQ key={index} data={slice} />;

          default:
            return <pre>{JSON.stringify(slice, null, 2)}</pre>;
        }
      })}
    </Layout>
  );
}

export const query = graphql`
  query ($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      seo: data {
        meta_title
        meta_description
        meta_image {
          url
        }
      }
      data {
        body {
          ... on PrismicPageBodyText {
            id
            primary {
              text {
                html
                raw
              }
            }
            slice_type
          }
          ... on PrismicPageBodyContactForm {
            id
            slice_type
            primary {
              title {
                text
              }
            }
          }

          ... on PrismicPageBodyMedia {
            id
            slice_type
            primary {
              title {
                text
                html
              }
            }
            items {
              text
              title {
                text
              }
              link {
                type
                url
                link_type
              }
            }
          }
          ... on PrismicPageBodyCallToAction {
            ...CallToAction
          }
          ... on PrismicPageBodyOnlineShop {
            ...OnlineShop
          }
          ... on PrismicPageBodyFaqSection {
            id
            slice_type
            primary {
              header {
                html
                raw
              }
            }
            items {
              title {
                html
              }
              content: text {
                html
              }
            }
          }
        }
      }
    }
  }
`;

function Text({ html, raw }) {
  return (
    <div className="relative py-16 overflow-hidden bg-white dark:bg-black">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto text-lg prose dark:prose-dark">
          <RichText
            render={raw}
            linkResolver={(doc) => {
              switch (doc.type) {
                case "page":
                  return `/${doc.uid}`;
                case "location":
                  return `/location/${doc.uid}`;

                default:
                  return "/";
              }
            }}
            htmlSerializer={(type, element, content, children, key) => {
              switch (type) {
                case "paragraph":
                  if (element.text.match(/\<iframe/)) {
                    return (
                      <div
                        css={css`
                          iframe {
                            width: 100%;
                          }
                        `}
                        dangerouslySetInnerHTML={{ __html: element.text }}
                      ></div>
                    );
                  }
                  break;

                default:
                  break;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Form({ title }) {
  return (
    <div className="px-4 py-16 overflow-hidden sm:px-6 lg:px-8 lg:py-24 dark:text-black">
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-black dark:text-white sm:text-4xl">
            {title}
          </h2>
        </div>
        <div className="mt-12">
          <form
            action="/api/send-email"
            method="POST"
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="full_name"
                className="block text-sm font-medium text-gray-700 dark:text-orange"
              >
                Vor- und Nachname
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  autoComplete="organization"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-orange"
              >
                E-Mail Adresse*
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="ruckrufnummer"
                className="block text-sm font-medium text-gray-700 dark:text-orange"
              >
                Rückrufnummer
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="ruckrufnummer"
                  id="ruckrufnummer"
                  autoComplete="tel"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="auswahlen"
                className="block text-sm font-medium text-gray-700 dark:text-orange"
              >
                Bitte Auswahlen*
              </label>
              <div className="mt-1">
                <select
                  required
                  name="auswahlen"
                  id="auswahlen"
                  className="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                >
                  <option disabled selected value>
                    {" "}
                    ---{" "}
                  </option>
                  <option value="Lounge Reservierung">
                    Lounge Reservierung
                  </option>

                  <option value="Events">Events</option>
                  <option value="Öffnungszeiten">Öffnungszeiten</option>
                  <option value="Jobs und Karriere">Jobs und Karriere</option>
                  <option value="Sonstiges">Sonstiges</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 dark:text-orange"
              >
                Deine Nachricht an uns
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring-orange focus:border-orange"
                  defaultValue={""}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-orange hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
              >
                Senden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function MediaObject({ data }) {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl py-10 mx-auto">
        <div
          className="mb-6 text-lg prose dark:prose-dark"
          dangerouslySetInnerHTML={{ __html: data?.primary?.title?.html }}
        ></div>
        {data?.items?.map((item) => {
          return (
            <div className="flex py-4">
              <div className="flex-shrink-0 mr-4">
                <a
                  href={item?.link?.url}
                  target="_blank"
                  className="flex items-center justify-center w-16 h-16 text-white bg-gray-500 border border-gray-500 dark:text-gray-500 dark:bg-white"
                >
                  <FaDownload className="text-2xl " />
                </a>
              </div>
              <div>
                <h4 className="text-lg font-bold">{item?.title?.text}</h4>
                <p className="mt-1">{item?.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
